function Breakpoint() {
	var self = this;

	self.check = function() {
		if( application.width < Breakpoint.Small )
			return Breakpoint.Mobile;
		else if( application.width >= Breakpoint.Small && application.width < Breakpoint.Medium )
			return Breakpoint.Small;
		else if( application.width > Breakpoint.Medium && application.width < Breakpoint.Large )
			return Breakpoint.Medium;
		else if( application.width >= Breakpoint.Large )
			return Breakpoint.Large;
	}

}

//BREAKPOINT ENUM
Breakpoint.Mobile = 600;
Breakpoint.Small = 768;
Breakpoint.Medium = 992;
Breakpoint.Large = 1200;