function Disclosure() {

	var self = this;

	$('.disclosure-hide').on('click', function(event) {
		$(this).toggleClass('show');
		self.update();
	});

	self.removeDisclosure = function() {
		$(document).find('.disclosure-hide').removeClass('disclosure-hide');
	}

	self.show = function() {
		$('.disclosure-hide').addClass('show');
	}

	self.hide = function() {
		$('.disclosure-hide').removeClass('show');
	}

	self.update = function() {
		var ele = $( '.disclosure-hide' ),
			lineHeight = parseFloat(ele.css('line-height')),
			fontSize = parseFloat(ele.css('font-size')),
			emHeight = lineHeight / fontSize,
			targetMaxHeight;
		if( ele.hasClass( 'show' ) )
			targetMaxHeight = ele[0].scrollHeight;
		else if( application.width <= Breakpoint.Mobile )
			targetMaxHeight = ( emHeight * 3 ) + ( emHeight * 0.5 ) + "em";
		else
			targetMaxHeight = ( emHeight * 9 ) + ( emHeight * 0.5 ) + "em";
		
		ele.css( 'max-height', targetMaxHeight );
	}

}