//Class Declaration
function Application( _width ) {

	//Private Property Declaration
	var self = this;

	//Passes whether the application is in debug mode or not. Passing 'false ' will cause all logs to be ignored
	var debug = new Debug( false );

	//END Private Property Declaration

	//Public Property Declaration
	self.debug = debug;
	self.isIE9 = $('html').hasClass("lt-ie9");
	self.width = ( _width === undefined ) ? 0 : _width;

	self.breakpoint = new Breakpoint();
	//END Public Property Declaration


	//Initialization method.
	self.start = function() {

		debug.log( "Application Start" );

		self.autonav = new AutoNav();
		self.compliance = new Compliance();
		self.disclosure = new Disclosure();
		self.utilities = new Utilities();
		self.alerts = new Alerts();

		Utilities.UpdateResponsiveCopy( self.width );

		$('a.inactive').click(function() {
			return false;
		});

		Utilities.ConfigureDropDown();

		if( self.isIE9 )
			self.disclosure.removeDisclosure();

		self.disclosure.update();

		Utilities.ConfigureLeftNavActiveState();

		Utilities.EqualCol();

	};

	//Updates on Resize
	self.onresize = function( _width ) {
		debug.log( "Application resize width: " + self.width );
		self.width = _width;
		if( self.autonav != null )
			self.autonav.reset();
	};

	//Updates on HashChange
	self.onhashChange = function( _hash ) {
		debug.log( "onhashChange: " + _hash );
	};
}
