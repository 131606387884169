$(document).ready(function() {
     slidenavSelectConvert(); // Call listener function at run time
     slideNavActiveSelect();
});

function slideNavActiveSelect() {

     var page = window.location.href.substr(window.location.href.lastIndexOf("/")+1);
     // genralized sub-nav selection - throughout whole site
     $("div.sliding-nav nav ul div").each(function(){
          $(this).find("a").attr("href") == "./" + page && $(this).addClass("active");
     });

     // GWF active sub-footer deselection - only GWF for now
     $("#main.great-west-funds div.disclaimer small p a").each(function(){
          $(this).attr("href").toLowerCase() == "/empower-funds/" + page && $(this).addClass("disabled");
          $(this).attr("href").toLowerCase() == "/empower-funds/privacy/" + page && $(this).addClass("disabled");
     });

     // var  a = $("div.sliding-nav nav ul div"),
     //      b = window.location.pathname.split("/");
     //      navLink = $("div.sliding-nav nav ul div li").attr("href");
     // "index.shtml"==b.slice(-1)[0]?(console.log("home"),$(a).toggleClass('active', true)):
     // "fund-performance.shtml"==b.slice(-1)[0]?(console.log("performance"),$(a).toggleClass('active', true)):
     // "fund-documents.shtml"==b.slice(-1)[0]?(console.log("docs"),$(a).toggleClass('active', true)):
     // "fund-commentaries.shtml"==b.slice(-1)[0]?(console.log("commentaries"),$(a).toggleClass('active', true)):
     // "core-strategies-funds.shtml"==b.slice(-1)[0]?(console.log("core"),$(a).toggleClass('active', true)):
     // "money-market-funds.shtml"==b.slice(-1)[0]&&(console.log("money market"),$(a).toggleClass('active', true));
     // $(navLink).parents('div').toggleClass('active', true);

     //-- Modal logic for CITs. No longer needed. GW Trust Co is not being carred over anymore as of 11/18/20 --//

     // var url = window.location.href;
     // if(url.indexOf('/collective-investment-trusts/index.shtml' || '/collective-investment-trusts/financial-information.shtml')) {
     //    $('#citsModal').modal('show');
     // }

     // // when close button clicked simulate back
     // $('.modal .modal-footer .accept-btn, .modal .close').on('click', function(){
     //      window.history.back();
     // });
     // // when esc pressed when modal open simulate back
     // $('div.modal').keyup(function(e) {
     //      if (e.keyCode === 27){
     //           window.history.back();          
     //      }
     // });

}

function slidenavSelectConvert() {

     // define the variable as URL/href
     var  currentPage = window.location.href,
          tabbedNavLocations = [
          "/perspectives/", 
          "/empower-funds/", 
          "/collective-investment-trusts/",
          "/institutional-separate-accounts/"
     ];

     // Create the dropdown base
     $("<select class='sliding-nav-dropdown'/>").appendTo("div.sliding-nav");

     // Create default option "Go to..."
     if (currentPage) {
          $("<option />", {
               "disabled" : true,
               "text"    : "Go to...",
               "class"   : "default-option"
          }).appendTo("div.sliding-nav select");

     }

     if (currentPage.indexOf(tabbedNavLocations) > -1) {

          $("div.sliding-nav select")[0].selectedIndex = currentPage;

     }

     if (currentPage.indexOf("/perspectives/") > -1) {

          // console.log("perspectives");
          $("div.sliding-nav #perspectives-nav").css('display', 'flex').siblings().remove();

     } else if (currentPage.indexOf("/empower-funds/") > -1) {

          // console.log("gwf");
          $("div.sliding-nav #gwf-nav").css('display', 'flex').siblings().remove();

     } else if (currentPage.indexOf("/collective-investment-trusts/") > -1) {

          // console.log("cits");
          $("div.sliding-nav #cits-nav").css('display', 'flex').siblings().remove();

     } else if (currentPage.indexOf("/institutional-separate-accounts/") > -1) {

          // console.log("cits");
          $("div.sliding-nav #isa-nav").css('display', 'flex').siblings().remove();


     }

     // Populate dropdown with menu items
     $("div.sliding-nav nav ul li a").each(function(i, elem) {

          var el = $(this);
          var href = el.attr("href");

          //assign the option element a value with the href of the li and add it to the select element
          $("<option />", {
              "value"   : href,
              "text"    : el.text()
          }).appendTo("div.sliding-nav select");

          if (href == currentPage) {
               currentPage = i + 1; // +1 because of additional 'Go to' option
               console.log(currentPage);
          }
     });

     // get the value of the selected option to switch urls when option is selected
     $("div.sliding-nav select").change(function() {
          window.location = $(this).find("option:selected").val();
     });
 
}