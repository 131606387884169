function Compliance() {
	var 	self = this,
			debug = application.debug;

	self.IDs = [ 	{"key" : "", 						"value" : "264769",	"date" : "(04/16)"},
					{"key" : "working-with-us", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "retirement-income", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "life-insurance", 			"value" : "264769",	"date" : "(04/16)"},
					{"key" : "investment-options", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "business-solutions", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "who-we-are", 				"value" : "264769",	"date" : "(04/16)"},
					{"key" : "ratings", 				"value" : "264769",	"date" : "(04/16)"},
					{"key" : "at-a-glance", 			"value" : "264769",	"date" : "(04/16)"},
					{"key" : "corporate-structure", 	"value" : "264769",	"date" : "(04/16)"},
					{"key" : "community-impact", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "leadership", 				"value" : "264769",	"date" : "(04/16)"},
					{"key" : "values", 					"value" : "264769",	"date" : "(04/16)"},
					{"key" : "history", 				"value" : "264769",	"date" : "(04/16)"},
					{"key" : "media-releases", 			"value" : "264769",	"date" : "(04/16)"},
					{"key" : "regulatory-filings", 		"value" : "264769",	"date" : "(04/16)"},
					{"key" : "careers", 				"value" : "264769",	"date" : "(04/16)"},
					{"key" : "contact-us", 				"value" : "264769",	"date" : "(04/16)"} 
			];

	self.injectPTNumber = function() {
		var complianceNumberElement = $('#compliance-number');
		var complianceDateElement = $('#compliance-date');
		var location = document.location.href;

		if(complianceNumberElement.length === 0) return;

		self.IDs.forEach(function(e) {
			if(location.indexOf(e["key"]) != -1) {
				complianceNumberElement[0].innerHTML = e["value"].toString();
				complianceDateElement[0].innerHTML = e["date"].toString();
			}
		} );

	}
	self.injectPTNumber();

}

