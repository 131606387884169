function Debug( _isDebug ) {
	
	var self = this;
	self.isDebug = _isDebug;

	self.log = function( _message ) {
		if( self.isDebug )
			console.log( _message );
	}

	self.warn = function( _message ) {
		if( self.isDebug )
			console.warn( _message );
	}

	self.error = function ( _message ) {
		if( self.isDebug )
			console.error( _message );
	}

}