function Alerts() {

	$.ajax({
		url : '/AlertConfig.json',
		dataType : 'json',
		success : processAlerts,
		error : function( err ) {
			debug.logError('failed to load alert config');
		}

	});

	function processAlerts( response ) {
		var hashVals = window.location.hash.replace("#", '').split(':');
		
		if( response.ShowAlert || hashVals[0] === 'TestAlerts' ) {
			if( hashVals[1] === undefined )
				$( ".alerts .alert-message" ).html( response.AlertMessage );
			else
				$( ".alerts .alert-message" ).html( hashVals[1] );

			if( response.HighPriority ) {
				$( ".alerts" ).removeClass( 'warning' );
				$( ".alerts" ).addClass( 'danger' );
			}
			$( ".alerts" ).slideDown(600);

			$( ".dismiss" ).click(function() { 
			  	$( ".alerts" ).slideUp( 400, function() {
			    		// Animation complete.
			  	});
			});
		}
	}
}