function Utilities() {
	var self = this,
		debug = application.debug;

	initUtilities();

}

function initUtilities() {
	convertImgsToSVG();
	activeProductHover();
	ignoreBlankAnchors();
	setupRedirectAnchors();
	objectKeyBackwardsCompatability();
}

function convertImgsToSVG() {

	$( 'img.svg' ).each( function(){

		var img = $(this),
			ID = img.attr( 'id' ),
			imgclass = img.attr( 'class' );
			url = img.attr( 'src' );

		$.get( url, function( data ){

			var svg = $(data).find( 'svg' );

			if( typeof id !== 'undefined') {
				svg = svg.attr('id', ID);
			}

			if( imgclass !== 'undefined') {
                	svg = svg.attr( 'class', imgclass + ' replaced-svg' );
            	}

            	svg = svg.removeAttr('xmlns:a');

            	if(!svg.attr('viewBox') && svg.attr('height') && svg.attr('width')) {
                	svg.attr('viewBox', '0 0 ' + svg.attr('height') + ' ' + svg.attr('width'));
            	}

            	// Grab all the events on the img, and binds it to the newly created SVG
            	// This is to avoid race conditions when events are bound before 
            	// the converter finishes replacing the image
            	$.each( $._data( img[0], "events" ), function() {
            		svg.bind( $(this)[0].type, $(this)[0].handler );
            	} );

            	img.replaceWith( svg );
    
		}, 'xml' );

	} );

}

function activeProductHover() {
	$(".products>div>div").hover(function() {			
		if(!$(this).hasClass('last')) {
			$(this).toggleClass("active-product");
		}
	});
}

function ignoreBlankAnchors() {
	$("a[href$='#']").click( function(e) {
		e.preventDefault();
	} );
}

function setupRedirectAnchors() {
	//for each anchor tag with 'data-target=#redirectModal' attribute
	$( 'a[data-target="#redirectModal"]' ).each( function() {
		// on click event
		$(this).on( 'click', function() {
			// variable href set to href of anchor tag with data-attribute=#redirectModal
			var href = $(this).attr( 'href' );
			// for element with #redirectModal, .redirect-btn, which is the anchor tag
			// change the .redirect-btn href to the variable href, which is the href for the a tag with attribute [data-target="#redirectModal"]
			$( '#redirectModal .redirect-btn' ).attr( 'href', href );
		} );
	} );
}

function objectKeyBackwardsCompatability() {
	if (!Object.keys) Object.keys = function(o) {
	  	if (o !== Object(o))
	    		throw new TypeError('Object.keys called on a non-object');
	  	var k=[],p;
	  	for (p in o) if (Object.prototype.hasOwnProperty.call(o,p)) k.push(p);
	  	return k;
	}
}

Utilities.EqualCol = function() {
	
	var colSizes = new Array();
	colSizes.push( { "key" : ".col-equal", "value" : true } );
	colSizes.push( { "key" : ".col-xs-equal", "value" : (application.width < Breakpoint.Mobile) } );
	colSizes.push( { "key" : ".col-sm-equal", "value" : (application.width < Breakpoint.Medium) } );
	colSizes.push( { "key" : ".col-md-equal", "value" : (application.width < Breakpoint.Large) } );
	colSizes.push( { "key" : ".col-lg-equal", "value" : (application.width > Breakpoint.Large) } );

	for(var i = 0; i < colSizes.length; i++) {
		var element = $( colSizes[i].key );
		if( element.length && colSizes[i].value )
			update( element );
	}

	function update( $rows ) {
		for (var i = 0; i < $rows.length; i++) {
			var tallest = 0,
				$row = $($rows[i]);
			$row.children().removeAttr('style');
			$row.children().each(function() {
				var height = $(this).height();
				if (height>tallest) tallest = height;
			});
			$row.children().height(tallest);
		}
	}
}

Utilities.UpdateResponsiveCopy = function( _width ) {
	( _width > Breakpoint.Mobile ) ? responsiveCopy( 'data-text-long' ) : responsiveCopy( 'data-text-short' );

	function responsiveCopy( _sizeClass ) {
		$(".responsive-copy").each(function(index) {
			$(this).html($(this).attr(_sizeClass));
		});
	}

}

Utilities.ConfigureDropDown = function() {
	/* Profile Menu touch fix */
	$( "#header-links .dropdown" ).on( 'click', function(event) {
		if( window.Touch ) {
			$( this ).toggleClass( "active" );
		}
	});

	/* Main nav touch fix */
	$( '.nav>li' ).on( 'touchstart', function(e) {
		if ( application.width >= Breakpoint.Mobile ) {
			var $this = $( this );
			$( '.nav>li' ).not( this ).removeClass( 'touchtoggle' );
			if ( $this.children( '.subnav' ).length && !$this.hasClass( 'touchtoggle' ) ) {
				e.preventDefault();
				e.stopPropagation();
				$this.addClass( 'touchtoggle' );
			}
		}
	});

	/* Secondary Nav */
	$( "h1.dropdown" ).on( 'click', function( event ) {
		var colSubnav = $( '.secondary-nav' );
		if( application.width <= Breakpoint.Mobile && colSubnav[0] ) {
			$( ".secondary-nav" ).slideToggle( "fast" );
			$( this ).toggleClass( "active" );
		}
	});

	/* Data attribute controlled dropdown */
	var dropdownTrigger = $( 'body' ).find( '[data-dropdown], [data-dropdown-xs], [data-dropdown-sm], [data-dropdown-md], [data-dropdown-lg]' );
	dropdownTrigger.on( 'click', function( event ){
		var 	$this = $( this ),
			data = $this.attr( 'data-dropdown' ),
			dataMobile = $this.attr( 'data-dropdown-xs' ),
			isMobileOnly = dataMobile ? true : false,
			subnav = data ? $( data ) : $( dataMobile );
		function toggle() {
			subnav.slideToggle( "fast" );
			$this.toggleClass( "active" );
		}
		if( subnav ) {
			if( !isMobileOnly )
				toggle();
			else if( application.width <= Breakpoint.Mobile )
				toggle();
		} else {
			logger.error("Target id specified not found: ", this);
		}
	});
}

Utilities.ConfigureLeftNavActiveState = function() {
	// Super complicated way to get the left nav active state working
	$( '#main .secondary-nav li a' ).each(function() {
		var docPath = window.location.pathname;
		var strippedDocPath = docPath.replace( /\/+$/, "" );
		var docPathArray = strippedDocPath.split( '/' );
		var docLastPart = docPathArray[docPathArray.length - 1];

		var secNavHref = $( this ).attr( 'href' );
		var strippedSecNavHref = secNavHref.replace( /\/+$/, "" );
		var secNavHrefArray = strippedSecNavHref.split( '/' );
		var secNavHrefLastPart = secNavHrefArray[secNavHrefArray.length - 1];

		if ( docLastPart == secNavHrefLastPart ){
			$( this ).addClass( "active" );
		}
	});

	// IE8 fix
	if( application.isIE9 ) {
		$('#cse-search-box-menu input').keypress(function (e) {
			if(e.which === 13) {
				$("#cse-search-box-menu").submit();
			}
		});
	}
}
