//Class Declaration
function AutoNav() {

	var self = this,
		debug = application.debug,
		breakpoint = Breakpoint.Small,
		isIE8 = $('html').hasClass('ie8');

	var navs = [];
	var mobileOnly = false;

	//INIT
	$('[class*=auto-nav]').each(function(e) {
		var $autonav = $(this);

		var navSize = $autonav.attr('class');

		if( navSize.indexOf( "sm" ) !== -1 ) {
			breakpoint = Breakpoint.Small;
			mobileOnly = true;
		}
		else if( navSize.indexOf( "md" ) !== -1 )
			breakpoint = Breakpoint.Medium;
		else if( navSize.indexOf( "lg" ) !== -1 )
			breakpoint = Breakpoint.Large;
		else
			breakpoint = Breakpoint.small;

		navs.push( new AutoNavObject( breakpoint, $autonav, mobileOnly ) );
	});
	//END INIT
	// Removes style attribute of navs when transitioning to each breakpoint (display:none)
	self.reset = function() {
		for( var i = 0; i < navs.length; i++ ) {
			if( navs[i].breakpoint > application.breakpoint.check() ) {
				navs[i].reset();
				$( '.nav' ).attr( 'style', '' );
			}
		}
	}
}
AutoNav.Speed = 300;
AutoNav.OpenIcon = "gwicon-before-plus";
AutoNav.CloseIcon = "gwicon-before-minus";
AutoNav.Criteria = 'a, h4, h5';

function AutoNavObject( _breakpoint, _root, _mobileOnly ) {

	var 	self = this,
		debug = application.debug,
		root = _root,
		mobileOnly = _mobileOnly;

	var toggleBtns = [];

	self.breakpoint = _breakpoint;

	//	Finds all Toggle buttons based on criteria
	//	Binds them to a click event
	//	And adds them to an array
	root.find( AutoNav.Criteria ).each( function(e) {
		var thisHref = root.attr( 'href' ),
			hasHref = !( thisHref === undefined || thisHref === '#' );
		if( !hasHref ) {
			var btn = $(this);
			btn.on( 'click', function(e) {
				debug.log("app bp: " + application.breakpoint.check() + ", " + self.breakpoint);

				if(application.breakpoint.check() < Breakpoint.Small || !mobileOnly) {
					if( !$(this).hasClass('active'))
						self.open( $(this) );
					else
						self.close( $(this) );
				}

			} );
			toggleBtns.push( btn );
		}
	} );

	// Opens a subnav
	self.open = function( btn ) {
		var subnav = btn.siblings( 'ul:first' );
		self.closeSubNav( subnav );
		btn.addClass( 'active' ).children( '.gwicon' ).removeClass( AutoNav.OpenIcon ).addClass( AutoNav.CloseIcon );
		subnav.slideDown( AutoNav.Speed );
	}

	// Close a subnav
	self.close = function( btn ) {
		var subnav = btn.siblings( 'ul:first' );
		btn.removeClass( 'active' ).children( '.gwicon' ).removeClass( AutoNav.CloseIcon ).addClass( AutoNav.OpenIcon );
		subnav.slideUp( AutoNav.Speed );
	}

	// Closes all open subnavs at tier level
	self.closeSubNav = function( subnav ) {
		var navTierItems = subnav.parent().siblings( 'li' );
		navTierItems.find( AutoNav.Criteria ).each(function(e) {
			if( $(this).hasClass('active') )
			{
				self.close($(this), $(this).parent().children('ul'));
			}
		} );
	}

	// Closes ALL subnavs
	self.reset = function() {
		for( var i = 0; i < toggleBtns.length; i++ ) {
			var btn = toggleBtns[i],
				subnav = btn.siblings( 'ul:first' );
			self.close( btn );
		}
	}

}
